import { classifyService } from '@/api';
import installationNoticeService from '@/api/installation-management/installation-notice';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import { ProductProjectListQuery, ProjectProductInstallation } from '@/resource/model';
import { dateFormat, messageError } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectProjectProduct extends mixins(DialogMixin, PagingMixin) {
  public tableOptions: OsTableOption<ProjectProductInstallation> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: (): string => {
      return 'itemId';
    }
  };

  public columnOptions: Array<OsTableColumn<ProjectProductInstallation>> = [
    {
      type: 'selection',
      prop: 'itemId',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductInstallation).visibleWidth} × ${
          (row as ProjectProductInstallation).visibleHeight
        }`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductInstallation).finishWidth} × ${
          (row as ProjectProductInstallation).finishHeight
        }`;
      }
    },
    {
      prop: 'prepressPicture',
      label: 'projectProduct.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'projectProduct.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'projectProduct.requiredDeliveryTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductInstallation).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'requiredArriveTime',
      label: 'projectProduct.requiredArriveTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductInstallation).requiredArriveTime, 'YYYY-MM-DD HH:mm');
      }
    }
  ];

  public queryItemsOption: Array<OsQueryItemOption<ProductProjectListQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      option: {
        placeholder: 'consumed.inputItemCode'
      },
      colSpan: 8
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      option: {
        placeholder: 'projectProduct.inputPointName'
      },
      colSpan: 8
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'projectProduct.platformProduct',
      option: {
        placeholder: 'consumed.inputPlatformProduct'
      },
      colSpan: 8
    },
    {
      type: 'Input',
      field: 'customerProductName',
      label: 'projectProduct.customerProduct',
      option: {
        placeholder: 'projectProduct.inputCustomerProduct'
      },
      colSpan: 8
    },
    {
      type: 'Cascader',
      field: 'categoryId',
      label: 'product.productClassify',
      colSpan: 8,
      option: {
        placeholder: 'product.selectClassify',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      optionData: []
    },
    {
      type: 'DateRangePicker',
      field: 'requiredDeliveryTime',
      label: 'projectProduct.shippingDate',
      option: {
        rangeSeparator: '~'
      },
      colSpan: 8
    }
  ];

  @InjectReactive()
  public readonly projectId!: number;

  public queryForm: ProductProjectListQuery = {
    projectId: this.projectId
  };

  public selectedRows: Array<ProjectProductInstallation> = [];

  public dialogOpen(): void {
    this.getProductClassifies();
    this.getProjectProducts();
  }

  public handleSelectionChange(selectedValues: Array<ProjectProductInstallation>): void {
    this.selectedRows = selectedValues;
  }

  public onSubmit(): void {
    this.$emit('select-confirm', this.selectedRows);
    this.syncedVisible = false;
  }

  public getClassifyEffect(id: number): string {
    return this.queryForm.categoryId === id ? 'dark' : 'plain';
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public reloadData(): void {
    (this.$refs.productTable as OsTable).clearSelection();
    this.paging.showCount = 50;
    this.getProjectProducts();
  }

  public getProjectProducts(): void {
    this.tableOptions.loading = true;
    this.queryForm.projectId = this.projectId;
    installationNoticeService
      .getProjectProduct(this.queryForm, this.paging)
      .then(res => {
        this.tableOptions.data = res.data || [];
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOptions.loading = false;
      });
  }

  private getProductClassifies(): void {
    classifyService
      .getTreeData()
      .then(res => {
        const option = this.queryItemsOption.find(x => x.field === 'categoryId');
        if (!option) {
          return;
        }
        option.optionData = classifyService.handleCascaderOption(res as any);
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.requiredDeliveryTime')
  private handleDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
