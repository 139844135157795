import { DialogMixin } from '@/mixins/dialog';
import { ServiceItemList, ShopList } from '@/resource/model';
import { ElForm } from 'element-ui/types/form';
import { Component, Emit, Mixins } from 'vue-property-decorator';
import SelectServiceItem from '@/views/dialogs/select-service-item/select-service-item.vue';
import { limitFutureForTimePicker, messageError } from '@/utils';

export type BatchUpdateShopForm = Partial<
  Pick<ShopList, 'budgetAmount' | 'planInstallTime' | 'remark' | 'finishDay' | 'serveItems'>
>;

@Component({
  components: { SelectServiceItem }
})
export default class BatchUpdate extends Mixins(DialogMixin) {
  public form: BatchUpdateShopForm = {
    finishDay: '',
    serveItems: '',
    budgetAmount: undefined,
    remark: ''
  };

  public formRules: { [P in keyof Partial<BatchUpdateShopForm>]: Array<object> } = {
    // finishDay: [
    //   {
    //     required: true,
    //     validator: (rule: any, value: string, callback: Function): void => {
    //       if (!value) {
    //         callback(new Error(translation('addInstallationNotice.selectFinishInstallTime')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'blur'
    //   }
    // ],
    // serveItems: [
    //   {
    //     required: true,
    //     validator: (rule: any, value: string, callback: Function): void => {
    //       if (!value) {
    //         callback(new Error(translation('请填写服务项目')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'change'
    //   }
    // ]
  };

  public selectserviceItemDialog: boolean = false;
  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public openSelectserviceItemDialog(): void {
    this.selectserviceItemDialog = true;
  }

  public handleSelectServiceItem(serviceItem: ServiceItemList): void {
    this.form.serveItems = serviceItem.name;
  }

  public async onSubmit(): Promise<void> {
    try {
      await (this.$refs.formRef as ElForm).validate();
      this.setLoading(true);
      this.confirm(this.form);
      (this.$refs.formRef as ElForm).resetFields();
      this.closeDialog();
    } catch (error) {
      messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  @Emit()
  private confirm(form: BatchUpdateShopForm): BatchUpdateShopForm {
    return form;
  }
}
