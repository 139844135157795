import { DialogMixin } from '@/mixins/dialog';
import { ServiceItemList, ShopList } from '@/resource/model';
import { isNullOrUndefinedForBaseType, limitFutureForTimePicker, translation } from '@/utils';
import { telOrMobile } from '@/utils/validate';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import SelectServiceItem from '@/views/dialogs/select-service-item/select-service-item.vue';

type ShopForm = Partial<Omit<ShopList, 'id' | 'installationNoticeId'>> & {
  area: Array<string>;
};

@Component({
  components: { SelectServiceItem }
})
export default class CustomSite extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: null
  })
  public shopInstallation!: ShopList;

  public shopForm: ShopForm = {
    shopName: '',
    contacts: '',
    contactsTel: '',
    area: [],
    address: '',
    budgetAmount: undefined,
    finishDay: undefined,
    remark: '',
    serveItems: ''
  };

  public shopFormRules: { [prop in keyof ShopForm]: Array<Object> } = {
    shopName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addInstallationNotice.inputSite')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    contacts: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addInstallationNotice.inputContactsPerson')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    contactsTel: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addInstallationNotice.inputContactsPhone')));
            return;
          }
          if (!telOrMobile(value)) {
            callback(new Error(translation('common.inputLegalTel')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    area: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('addDeliveryNotice.selectArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    address: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addDeliveryNotice.inputAddress')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    finishDay: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addInstallationNotice.selectFinishInstallTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    budgetAmount: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (isNullOrUndefinedForBaseType(value)) {
            callback(new Error(translation('addInstallationNotice.inputBudgetAmount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    serveItems: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('请填写服务项目')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public selectserviceItemDialog: boolean = false;

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public openSelectserviceItemDialog(): void {
    this.selectserviceItemDialog = true;
  }

  public handleSelectServiceItem(serviceItem: ServiceItemList): void {
    this.shopForm.serveItems = serviceItem.name;
  }

  public created(): void {
    Object.assign(this.shopForm, this.shopInstallation);
    this.shopForm.area = [this.shopForm.province!, this.shopForm.city!, this.shopForm.district!].filter(item => !!item);
  }

  public onSubmit(): void {
    (this.$refs.shopForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      const [province, city, district] = this.shopForm.area;
      this.shopForm.province = province;
      this.shopForm.city = city;
      this.shopForm.district = district;
      this.$emit('edit-success', this.shopForm);
      this.closeDialog();
    });
  }
}
