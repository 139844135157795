import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { ProductInstallationCount, ProjectProductInstallation, ShopItemReList } from '@/resource/model';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { Message, Notification } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import SelectProjectProduct from './select-project-product/select-project-product.vue';
@Component({
  components: { SelectProjectProduct }
})
export default class ProductInstallationDetails extends mixins(PagingMixin) {
  @PropSync('productInstallations', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<ShopItemReList>;

  /**
   * 产品项次剩余可发货数量
   */
  @Prop({
    required: true,
    type: Array
  })
  public productInstallationCount!: Array<ProductInstallationCount>;

  public tableOptions: OsTableOption<Partial<ShopItemReList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<ShopItemReList>> = [
    {
      type: 'selection',
      prop: 'itemId',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ShopItemReList).visibleWidth} × ${(row as ShopItemReList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ShopItemReList).finishWidth} × ${(row as ShopItemReList).finishHeight}`;
      }
    },
    {
      prop: 'productCount',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remainCount',
      label: 'addInstallationNotice.remainCount',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'addInstallationNotice.installCount',
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.selectProduct',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: '',
      handleClick: (): void => {
        this.openAddProductDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: '',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteProduct();
      }
    }
  ];

  public selectedProducts: Array<ShopItemReList> = [];

  public addProductDialog = false;

  public productInstallation: ShopItemReList | null = null;

  private readonly canEditCells: Array<Partial<keyof ShopItemReList>> = ['count', 'remark'];

  public created(): void {
    if (this.tableData.length > 0) {
      this.tableData.forEach(x => {
        this.initInputDynamicProp(x, this.canEditCells);
      });
      this.pagingData();
    }
  }

  public pagingData(): void {
    this.totalData = this.tableData.length;
    this.tableOptions.data = this.tableData.slice(
      (this.paging.currentPage - 1) * this.paging.showCount,
      this.paging.currentPage * this.paging.showCount
    );
  }

  public handleProductSelection(products: Array<ShopItemReList>): void {
    this.selectedProducts = products;
  }

  public handleAddProductConfirm(products: Array<ProjectProductInstallation>): void {
    // 去除已添加的
    const itemIds = this.tableData.map(x => x.itemId);
    let errorMsg = '';
    products
      .filter(x => !itemIds.includes(x.itemId))
      .forEach(x => {
        const remainCount = this.calculateRemainCount(x.itemId, x.count);
        if (remainCount === 0) {
          errorMsg = `${errorMsg}${x.itemCode}</br>`;
          return;
        }
        const obj = {
          ...x,
          productCount: x.count,
          remainCount: this.calculateRemainCount(x.itemId, x.count),
          remark: ''
        };
        this.initInputDynamicProp(obj, this.canEditCells);
        // 安装数量默认为可安装的最大数量
        Vue.set(obj, 'count', obj.remainCount);
        this.tableData.unshift(obj as any);
      });
    if (errorMsg) {
      Notification.warning({
        title: translation('tip.tipInfo'),
        duration: 0,
        dangerouslyUseHTMLString: true,
        message: `${errorMsg}${translation('addInstallationNotice.verifyRemainCount')}`
      });
    }

    // 添加完产品项次后回到第一页
    this.paging.currentPage = 1;
    this.pagingData();
  }

  public showInstallCount(
    installationProduct: ShopItemReList & {
      countInput: boolean;
    }
  ): void {
    installationProduct.countInput = true;
  }

  public handleInstallCountBlur(
    installationProduct: ShopItemReList & {
      countInput: boolean;
    }
  ): void {
    if (!installationProduct.count) {
      installationProduct.count = 0;
      installationProduct.countInput = !installationProduct.countInput;
      return;
    }

    if (Number(installationProduct.count) > installationProduct.remainCount) {
      Message.error(`${translation('addInstallationNotice.remainInstallCount')}${installationProduct.remainCount}`);
      installationProduct.count = installationProduct.remainCount;
    } else if (Number(installationProduct.count) < 0) {
      Message.error(`${translation('addInstallationNotice.minRemainInstallCount')}${installationProduct.remainCount}`);
      installationProduct.count = 0;
    }

    installationProduct.countInput = false;
  }

  public showRemarkInput(
    installationProduct: ShopItemReList & {
      remarkInput: boolean;
    }
  ): void {
    installationProduct.remarkInput = true;
  }

  public hideRemarkInput(
    installationProduct: ShopItemReList & {
      remarkInput: boolean;
    }
  ): void {
    installationProduct.remarkInput = false;
  }

  private batchDeleteProduct(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 未保存到数据库的数据，使用项次Id进行删除即可
          const itemIds = this.selectedProducts.map(x => x.itemId);
          if (itemIds.length > 0) {
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法
            const filterData = this.tableData.filter(x => !itemIds.includes(x.itemId!));
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }

          // 处理勾选问题
          (this.$refs.installationProductTable as OsTable).clearSelection();
          this.selectedProducts = [];

          // 处理分页
          this.pagingData();
          // 处理项次的剩余可安装数量
          this.$emit('install-count-change');
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openAddProductDialog(): void {
    this.addProductDialog = true;
  }

  @Watch('selectedProducts')
  private handleSelectedChanged(value: Array<ShopItemReList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType === 'batchDelete') {
        x.disabled = value.length === 0;
      }
    });
  }

  /**
   * 计算剩余可发货数量
   * @param itemId 项次id
   * @param productCount 项次总数
   * @returns
   */
  private calculateRemainCount(itemId: number, productCount: number): number {
    const sentCount = this.productInstallationCount.find(x => x.itemId === itemId)?.sentCount ?? 0;
    return Number(productCount) - Number(sentCount);
  }

  /**
   * 初始化项次内，部分可以动态使用input编辑的属性
   * @param rowObj行对象
   * @param canEditCells 要初始化的列
   */
  private initInputDynamicProp(rowObj: any, canEditCells: Array<string>): void {
    canEditCells.forEach(x => {
      Vue.set(rowObj, `${x}Input`, false);
    });
  }
}
