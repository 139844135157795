import minioService from '@/api/minio';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { Operation, OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { InstallAttachmentsList, UploadedFile } from '@/resource/model';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { UploadFile } from '@/views/dialogs';
import { dateFormat, downloadFileByBlob, downloadFileByPath, messageError, messageErrors, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElTable } from 'element-ui/types/table';
import { FileService } from '@/api';
@Component({
  components: { UploadFile }
})
export default class InstallAttachment extends Vue {
  @Prop({
    required: true,
    type: Array
  })
  public data!: Array<InstallAttachmentsList>;

  /**
   * 是否显示操作按钮区域
   */
  @Prop({ type: Boolean, required: false, default: true })
  public showOperation!: boolean;

  /**
   * 表格工具栏配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      operationType: 'upload',
      slot: 'start',
      label: 'button.upload',
      type: 'primary',
      icon: 'el-icon-upload',
      dynamicHidden: (): boolean => {
        return !this.showOperation;
      },
      handleClick: this.openUploadDialog
    },
    {
      operationType: 'batchDelete',
      slot: 'start',
      label: 'button.batchDelete',
      icon: 'el-icon-delete',
      disabled: true,
      dynamicHidden: (): boolean => {
        return !this.showOperation;
      },
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      operationType: 'batchDownload',
      slot: 'end',
      label: 'button.download',
      icon: 'el-icon-download',
      handleClick: (): void => {
        this.batchDownload();
      }
    }
  ];

  public tableOptions: OsTableOption<InstallAttachmentsList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<InstallAttachmentsList>> = [
    {
      type: 'selection',
      prop: 'path',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'index',
      label: 'common.index',
      reserveSelection: true,
      fixed: true,
      width: '50px'
    },
    {
      prop: 'name',
      label: 'installationNotice.fileName',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'extension',
      label: 'installationNotice.fileExtension',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'size',
      label: 'installationNotice.fileSize',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `
        ${((row as InstallAttachmentsList).size / 1024).toFixed(0)}
        `;
      }
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOptions: RowOperation<InstallAttachmentsList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'download',
        type: 'text',
        label: 'button.ClickDownload',
        icon: 'el-icon-download',
        size: 'small',
        loading: false,
        handleClick: this.download
      }
    ]
  };

  public selectedRows: Array<InstallAttachmentsList> = [];

  public uploadVisible = false;

  /**
   * 待删除的文件
   */
  private pendingDeleteFiles: Array<string> = [];

  public created(): void {
    minioService.init();
    this.data.forEach((item, index) => {
      item.index = index + 1;
    });
    this.tableOptions.data = this.data;
  }

  public handleSelectionChange(selectedRows: Array<InstallAttachmentsList>): void {
    this.selectedRows = selectedRows;
    this.$emit('selection-change', this.selectedRows);
  }

  /**
   * 表格默认全选
   */
  public tableSelectAll(): void {
    this.$nextTick(() => {
      for (const item of this.data) {
        ((this.$refs.tableRef as Vue).$refs.osTable as ElTable).toggleRowSelection(item, true);
      }
      this.selectedRows = this.data;
      this.$emit('selection-change', this.selectedRows);
    });
  }

  public async download(attachment: InstallAttachmentsList, btn: Operation<InstallAttachmentsList>): Promise<void> {
    try {
      btn.loading = true;
      const path = await minioService.getDownLoadPath(attachment.path);
      downloadFileByPath(attachment.name, path);
    } catch (error) {
      messageError(error);
    } finally {
      btn.loading = false;
    }
  }

  public async batchDelete(): Promise<void> {
    try {
      const paths = this.selectedRows.map(x => x.path);
      this.tableOptions.data = this.tableOptions.data.filter(x => !paths.includes(x.path));
      this.selectedRows = [];
      (this.$refs.tableRef as OsTable).clearSelection();
      this.sync(this.tableOptions.data);
      this.pendingDeleteFiles.push(...paths);
      Message.success(translation('operationRes.deleteSuccess'));
    } catch (error) {
      Message.success(translation('operationRes.deleteFailed'));
      console.error(error);
    }
  }

  public async batchDownload(): Promise<void> {
    const fileList = this.selectedRows.map(x => x.id);

    if (fileList.length <= 0) {
      Message.warning(translation('没有可下载文件'));
      return;
    }
    try {
      FileService.downloadZip(fileList)
        .then(blob => {
          downloadFileByBlob(`${translation('installationNotice.installationAttachment')}_${dateFormat(new Date())}.zip`, blob);
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {});
    } catch (error) {
      messageErrors(error);
    }
  }

  public openUploadDialog(): void {
    this.uploadVisible = true;
  }

  /**
   * 删除minio已上传的文件
   * 需要父组件在合适的时机手动调用，
   * 因为某些情况比如编辑时，可能还没点保存，直接先删除文件会导致minio的文件被删除，而后续通过路径下载时会下载失败
   */
  public deleteMinioFiles(): void {
    minioService.removeFiles(this.pendingDeleteFiles).catch(error => {
      messageError(error);
    });
  }

  /**
   * 上传附件
   * @param requestOptions
   */
  private async handleAttachmentUploaded(files: Array<UploadedFile>): Promise<void> {
    this.uploadVisible = false;
    this.tableOptions.data.push(
      ...files.map(x => {
        x.size = Math.round(x.size);
        return x;
      })
    );
    this.tableOptions.data.forEach((item, index) => {
      item.index = index + 1;
    });
    this.sync(this.tableOptions.data);
  }

  @Watch('selectedRows')
  private handleSelectionChanged(rows: Array<InstallAttachmentsList>): void {
    this.operationOptions.forEach(option => {
      if (option.operationType === 'batchDelete') {
        option.disabled = rows.length === 0;
      }
    });
  }

  @Emit()
  private sync(data: Array<InstallAttachmentsList>): Array<InstallAttachmentsList> {
    return data;
  }

  @Watch('data')
  private handleDataChanged(): void {
    this.data.forEach((item, index) => {
      item.index = index + 1;
    });
    this.tableOptions.data = this.data;
  }
}
