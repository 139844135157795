import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { ImportRes, ProductInstallationCount, ProjectShopResource, ShopItemReList, ShopList } from '@/resource/model';
import {
  dateFormat,
  downloadFileByBlob,
  handleImportError,
  isNullOrUndefinedForBaseType,
  messageError,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message, Notification } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, PropSync, Vue, Watch } from 'vue-property-decorator';
import SelectProjectShop from './select-shop/select-shop.vue';
import CustomSite from './custom-site/custom-site.vue';
import installationNoticeService from '@/api/installation-management/installation-notice';
import BatchUpdate from './batch-update/batch-update.vue';
import { BatchUpdateShopForm } from './batch-update/batch-update';
import { uniqueId } from 'lodash-es';
import { ApiResponse } from '@/api/axios';
import { ImportFile } from '@/views/dialogs';
@Component({
  components: { SelectProjectShop, CustomSite, BatchUpdate, ImportFile }
})
export default class ShopInstallationDetails extends mixins(PagingMixin) {
  @PropSync('shopInstallations', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<Partial<ShopList>>;
  // 海报导入
  public posterImportDialogVisible: boolean = false;
  // POP导入
  public importPopDialogVisible: boolean = false;

  public tableOptions: OsTableOption<Partial<ShopList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<ShopList>> = [
    {
      type: 'selection',
      prop: 'shopId',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'index',
      label: 'common.index',
      minWidth: '60px',
      fixed: true
    },
    {
      prop: 'shopName',
      label: 'addInstallationNotice.shopName',
      minWidth: '150px',
      fixed: true,
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'addInstallationNotice.address',
      minWidth: '200px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `${(row as ShopList).province}
        ${(row as ShopList).city ? (row as ShopList).city : ''}
        ${(row as ShopList).district ? (row as ShopList).district : ''}
        ${(row as ShopList).address}`;
      }
    },
    {
      prop: 'contacts',
      label: 'addInstallationNotice.contactsPerson',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'contactsTel',
      label: 'addInstallationNotice.contactsPhone',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    // {
    //   prop: 'planInstallTime',
    //   label: 'addInstallationNotice.planInstallTime',
    //   minWidth: '150px',
    //   showOverflowTooltip: true,
    //   formatter: (row: object): string => {
    //     return dateFormat((row as ShopList).planInstallTime);
    //   }
    // },
    {
      prop: 'finishDay',
      label: 'addInstallationNotice.finishDay',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as ShopList).finishDay, 'YYYY-MM-DD');
      }
    },
    {
      prop: 'serveItems',
      label: 'addInstallationNotice.serviceItem',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'budgetAmount',
      label: 'addInstallationNotice.budgetAmount',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.addSite',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      handleClick: this.openAddShopDialog
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      plain: true,
      disabled: true,
      handleClick: this.batchDeleteShop
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.batchUpdate',
      operationType: 'batchUpdate',
      icon: 'el-icon-edit',
      plain: true,
      disabled: true,
      handleClick: this.openBatchUpdateDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'installationNotice.posterImport',
      operationType: 'import',
      handleClick: (): void => {
        this.openPosterImportFileDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'installationNotice.popImport',
      operationType: 'popImport',
      handleClick: (): void => {
        this.openPopImportFileDialog();
      }
    }
  ];

  public rowOperationOptions: RowOperation<ShopList> | null = {
    fixed: 'right',
    width: '120px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        handleClick: (item): void => {
          this.openCustomSiteDialog(item);
        }
      }
    ]
  };

  public selectedShops: Array<ShopList> = [];

  public addShopDialog = false;

  public customSiteDialog = false;

  public batchUpdateDialog = false;

  public shopInstallation: ShopList | null = null;

  @InjectReactive()
  private projectId!: number;

  public pagingData(): void {
    this.totalData = this.tableData.length;
    this.tableOptions.data = this.tableData.slice(
      (this.paging.currentPage - 1) * this.paging.showCount,
      this.paging.currentPage * this.paging.showCount
    );
  }

  public shopClick(installationShop: ShopList): void {
    this.$emit('shop-click', installationShop);
  }

  public handleShopSelection(shops: Array<ShopList>): void {
    this.selectedShops = shops;
  }

  public updateShopInfo(shop: ShopList): void {
    Object.assign(this.shopInstallation, shop);
    this.hackForWatchTable();
  }

  public async handleAddShopConfirm(shops: Array<ProjectShopResource>): Promise<void> {
    const addedShopIds = this.tableData.map(x => x.projectShopId);
    const toAddProjectShopIds = shops.filter(x => !addedShopIds.includes(x.id)).map(x => x.id!);
    if (toAddProjectShopIds.length === 0) {
      return;
    }
    try {
      this.tableOptions.loading = true;
      // 默认会根据项目门店id去查项目交货明细下配置的项次
      // 该接口同时返回项目门店相关信息，所以直接加进去就好
      const shopsRes = await installationNoticeService.getShopItemsByShops(toAddProjectShopIds);
      // 添加完门店后回到第一页
      this.paging.currentPage = 1;
      this.$emit('add-shops', shopsRes);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOptions.loading = false;
    }
  }

  public batchUpdateShop(form: BatchUpdateShopForm): void {
    for (const item of this.selectedShops) {
      if (form.remark) {
        item.remark = form.remark;
      }
      if (!isNullOrUndefinedForBaseType(form.budgetAmount)) {
        item.budgetAmount = form.budgetAmount!;
      }
      // if (form.planInstallTime) {
      //   item.planInstallTime = form.planInstallTime;
      // }
      if (form.finishDay) {
        item.finishDay = form.finishDay;
      }
      if (form.serveItems) {
        item.serveItems = form.serveItems;
      }
    }

    (this.$refs.shopTable as OsTable).clearSelection();
    this.selectedShops = [];
    this.hackForWatchTable();
  }

  private openCustomSiteDialog(shop: ShopList): void {
    this.customSiteDialog = true;
    this.shopInstallation = shop;
  }

  private batchDeleteShop(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 未保存到数据库的数据，使用uuid进行删除即可
          const uuids = this.selectedShops.filter(x => !x.id && x.uuid).map(x => x.uuid);

          if (uuids.length > 0) {
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法
            const filterData = this.tableData.filter(x => !uuids.includes(x.uuid!));
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }
          // 已保存到数据库的数据，需要先调用接口进行删除
          const shopIds = this.selectedShops.filter(x => !!x.projectShopId).map(x => x.projectShopId);
          if (shopIds.length > 0) {
            const filterData = this.tableData.filter(x => !shopIds.includes(x.projectShopId!));
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }

          this.$emit('shop-deleted', this.selectedShops);

          // 处理勾选问题
          (this.$refs.shopTable as OsTable).clearSelection();
          this.selectedShops = [];

          // 处理分页
          this.pagingData();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openAddShopDialog(): void {
    this.addShopDialog = true;
  }

  // 海报导入
  private openPosterImportFileDialog(): void {
    if (this.tableData.length === 0) {
      Message.error('请先添加门店在导入');
      return;
    }
    this.posterImportDialogVisible = true;
  }
  private downloadPosterTemplate(): void {
    (this.$refs.posterImportDialog as ImportFile).setDownloadLoading(true);
    installationNoticeService
      .downloadPosterTemplate(this.tableData)
      .then((blob: any) => {
        downloadFileByBlob('海报导入模板.xlsx', blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.posterImportDialog as ImportFile).setDownloadLoading(false);
      });
  }
  private importPoster(path: string): void {
    const projectId = this.projectId;
    installationNoticeService
      .importPoster(path, projectId)
      .then((res: any) => {
        this.posterImportDialogVisible = false;
        this.tableData.forEach(item => {
          res.forEach((cur: any) => {
            if (cur.shopName === item.shopName) {
              Vue.set(item, 'province', cur.province);
              Vue.set(item, 'city', cur.city);
              Vue.set(item, 'district', cur.district);
              Vue.set(item, 'address', cur.address);
              Vue.set(item, 'serveItems', cur.serveItems);
              Vue.set(item, 'finishDay', cur.finishDay);
              Vue.set(item, 'budgetAmount', cur.budgetAmount);
              Vue.set(item, 'contacts', cur.contacts);
              Vue.set(item, 'contactsTel', cur.contactsTel);
              Vue.set(item, 'remark', cur.remark);
            }
          });
        });
        // 导入成功
        Message.success(translation('dialog.importSuccess'));
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
      })
      .finally(() => {
        (this.$refs.posterImportDialog as ImportFile).setLoading(false);
      });
  }
  // POP导入
  private openPopImportFileDialog(): void {
    // if (this.tableData.length === 0) {
    //   Message.error('请先添加门店在导入');
    //   return;
    // }
    this.importPopDialogVisible = true;
  }
  private downloadPopTemplate(): void {
    (this.$refs.importPopDialog as ImportFile).setDownloadLoading(true);
    installationNoticeService
      .downloadPopTemplate(this.projectId)
      .then((blob: any) => {
        downloadFileByBlob('POP导入模板.xlsx', blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importPopDialog as ImportFile).setDownloadLoading(false);
      });
  }
  private ImportPop(path: string): void {
    installationNoticeService
      .importPop(path, this.projectId)
      .then((res: Array<ShopList>) => {
        // this.selectConfirm(res);
        this.updateInsert(res);
        this.importPopDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importPopDialog as ImportFile).setLoading(false);
      });
  }
  // 校验产品明细安装数量合法性
  private verificationItem(shopList: Array<ShopList>): boolean {
    const errorMsgList: Array<{ title: string; errorItem: Array<any> }> = [];
    const itemInstallationRecord: Array<ProductInstallationCount> = [];
    for (const shop of shopList) {
      const errorItem: Array<any> = [];
      for (const product of shop.shopItemRelList) {
        const index = itemInstallationRecord.findIndex(x => x.itemId === product.itemId);
        // 如果已存在该项次记录，则累加已发货数量

        if (index !== -1) {
          const sentCount = itemInstallationRecord[index].sentCount + Number(product.count);
          if (sentCount <= itemInstallationRecord[index].total) {
            itemInstallationRecord[index].sentCount = sentCount;
          } else {
            errorItem.push(product);
            // errorMsg `${errorMsg}${x.itemCode}</br>`
          }
        } else {
          itemInstallationRecord.push({
            itemId: product.itemId,
            itemCode: product.itemCode,
            sentCount: Number(product.count),
            total: Number(product.productCount)
          });
        }
      }
      if (errorItem.length > 0) {
        errorMsgList.push({
          title: shop.shopName,
          errorItem: errorItem
        });
      }
    }
    if (errorMsgList.length > 0) {
      let message = '';
      errorMsgList.forEach(item => {
        message =
          message +
          ` 【${item.title}】</br> ${item.errorItem
            .map(x => x.itemCode)
            .join('</br>')}</br> 该项次安装数量超出可安装数量</br></br>`;
      });

      Notification.warning({
        title: translation('tip.tipInfo'),
        duration: 0,
        dangerouslyUseHTMLString: true,
        message: message
      });
    }
    return errorMsgList.length === 0;
  }

  // private selectConfirm(shopList: Array<ShopList>): void {
  //   MessageBox.confirm('', translation('请选择数据插入模式'), {
  //     confirmButtonText: translation('覆盖替换'),
  //     cancelButtonText: translation('更新插入'),
  //     type: 'warning',
  //     showClose: true,
  //     distinguishCancelAndClose: true,
  //     beforeClose: (action, instance, done) => {
  //       console.log('action', action, instance);
  //       if (action === 'confirm') {
  //         // 全量覆盖
  //         this.coverInsert(shopList);
  //         done();
  //       } else if (action === 'cancel') {
  //         this.updateInsert(shopList);
  //         done();
  //       } else {
  //         Message.info(translation('operationRes.cancelOperation'));
  //         done();
  //       }
  //     }
  //   });
  // }

  private updateInsert(shopList: Array<ShopList>): void {
    // 插入
    const addedShopIds = this.tableData.map(x => x.shopId);
    const toAddProjectShop = shopList.filter(x => !addedShopIds.includes(x.shopId));
    const toUpdateProjectShop = shopList.filter(x => addedShopIds.includes(x.shopId));
    const copyShopList = [...this.tableData];

    copyShopList.forEach(item => {
      toUpdateProjectShop.forEach((cur: any) => {
        if (cur.shopName === item.shopName) {
          cur.shopItemRelList.forEach((x: any) => {
            Vue.set(x, `countInput`, false);
            Vue.set(x, `remarkInput`, false);
          });

          Vue.set(item, 'province', cur.province);
          Vue.set(item, 'city', cur.city);
          Vue.set(item, 'district', cur.district);
          Vue.set(item, 'address', cur.address);
          Vue.set(item, 'serveItems', cur.serveItems);
          Vue.set(item, 'finishDay', cur.finishDay);
          Vue.set(item, 'budgetAmount', cur.budgetAmount);
          Vue.set(item, 'contacts', cur.contacts);
          Vue.set(item, 'contactsTel', cur.contactsTel);
          Vue.set(item, 'remark', cur.remark);
          const addedItemIds = item.shopItemRelList?.map(x => x.itemId);
          const toAddShopItem = cur.shopItemRelList.filter((x: ShopItemReList) => !addedItemIds?.includes(x.itemId));
          const toUpdateShopItem = cur.shopItemRelList.filter((x: ShopItemReList) => addedItemIds?.includes(x.itemId));
          const copyShopItemList = [...item.shopItemRelList!];

          copyShopItemList?.forEach(shopItem => {
            // eslint-disable-next-line max-nested-callbacks
            toUpdateShopItem.forEach((z: any) => {
              if (shopItem.itemId === z.itemId) {
                shopItem.count = z.count;
              }
            });
          });
          toAddShopItem.forEach((obj: any) => {
            delete obj.id;
          });
          copyShopItemList.unshift(...toAddShopItem);
          Vue.set(item, 'shopItemRelList', copyShopItemList);
        }
      });
    });
    toAddProjectShop.forEach((obj: any) => {
      delete obj.id;
      obj.shopItemRelList.forEach((x: any) => {
        delete x.id;
      });
    });
    copyShopList.unshift(...toAddProjectShop);
    if (this.verificationItem(copyShopList as Array<ShopList>)) {
      this.tableData.splice(0);
      this.tableData?.unshift(...copyShopList);
      Message.success(translation('operationRes.operationSuccess'));
    }
  }

  // private coverInsert(shopList: Array<ShopList>): void {
  //   if (this.verificationItem(shopList)) {
  //     this.tableData.splice(0);
  //     this.tableData?.unshift(...shopList);
  //     Message.success(translation('operationRes.operationSuccess'));
  //   }
  // }

  @Watch('selectedShops')
  private handleSelectedChanged(value: Array<ShopList>): void {
    const dynamicBtns = ['batchDelete', 'batchUpdate'];
    this.operationOptions.forEach(x => {
      if (dynamicBtns.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('tableData')
  private handleDataChanged(values: Array<Partial<ShopList>>): void {
    values.forEach(x => {
      x.uuid = uniqueId('shop');
    });
    this.pagingData();
  }

  private openBatchUpdateDialog(): void {
    this.batchUpdateDialog = true;
  }

  /**
   * 由于vue2无法watch数组对象中的属性变化，所以以此方法来触发父组件Watch
   */
  private hackForWatchTable(): void {
    this.tableData.unshift({});
    this.tableData.shift();
  }
}
