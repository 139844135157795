import { installationNoticeService } from '@/api';
import { PagingMixin } from '@/mixins/paging';
import {
  InstallationNoticeInfo,
  ProductInstallationCount,
  CreateInstallationNotice,
  ShopItemReList,
  ShopList,
  InstallAttachmentsList
} from '@/resource/model';
import { translation, messageError, showConfirm, isNullOrUndefinedForBaseType, messageErrors } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import ShopInstallationDetails from './shop-installation-details/shop-installation-details.vue';
import ProductInstallationDetails from './product-installation-details/product-installation-details.vue';
import InstallAttachment from '../install-attachment/install-attachment.vue';
import { Route } from 'vue-router';
import { uniqueId } from 'lodash-es';
import { TagsViewModule } from '@/store/modules/tags-view';
import { ApiError } from '@/api/axios';

Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'AddInstallationNotice',
  components: { ShopInstallationDetails, ProductInstallationDetails, InstallAttachment }
})
export default class AddInstallationNotice extends mixins(PagingMixin) {
  public id = Number(this.$route.query.id);

  public title = '';

  public installationProductKey = 'uuid';

  public customerName = '';

  public saveLoading = false;

  public installationNoticeForm: Partial<CreateInstallationNotice> = {
    projectId: undefined,
    projectName: '',
    remark: '',
    shopList: [],
    fileIdList: []
  };

  public installationNoticeInfo: InstallationNoticeInfo | null = null;

  /**
   * 暂存的门店下要安装的项次列表
   */
  public productInstallations: Array<ShopItemReList> = [];

  /**
   * 当前正在进行分配发货产品的门店名称
   */
  public currentInstallationShopName = '';

  /**
   * 记录产品已添加数量
   */
  public productInstallationRecord: Array<ProductInstallationCount> = [];

  @ProvideReactive()
  private projectId = 0;

  private needReloadList = false;

  public get installAttachmentList(): Array<InstallAttachmentsList> {
    return this.installationNoticeInfo?.installAttachmentList || [];
  }

  public activated(): void {
    this.id = Number(this.$route.query.id);

    // 创建单据激活处理
    if (isNaN(this.id) && this.projectId !== Number(this.$route.query.projectId)) {
      TagsViewModule.RefreshCurrentTag();
    }

    // 编辑单据激活处理
    if (!isNaN(this.id)) {
      this.title = 'addInstallationNotice.editInstallationNotice';
      this.getInstallNoticeDetails();
    }
  }

  public created(): void {
    if (isNaN(this.id)) {
      this.title = 'addInstallationNotice.addInstallationNotice';
      this.initForAdd();
      return;
    }
  }

  public shopClick(installationShop: ShopList): void {
    this.handleProductInstallCount();
    installationShop.shopItemRelList.forEach(x => {
      x.remainCount = this.calculateRemainCount(x);
    });
    this.productInstallations = installationShop.shopItemRelList;
    this.currentInstallationShopName = ` —— ${installationShop.shopName}`;
    this.installationProductKey = installationShop.uuid;
  }

  public shopDeleted(deletedShops: Array<ShopList>): void {
    const deletedShopsUuids = deletedShops.map(x => x.uuid);
    if (deletedShopsUuids.includes(this.installationProductKey)) {
      this.installationProductKey = 'uuid';
    }
    this.handleProductInstallCount();
  }

  /**
   * 计算项次已安装的产品数量
   */
  public handleProductInstallCount(): void {
    this.productInstallationRecord = [];
    for (const shop of this.installationNoticeForm.shopList!) {
      for (const product of shop.shopItemRelList) {
        const index = this.productInstallationRecord.findIndex(x => x.itemId === product.itemId);
        // 如果已存在该项次记录，则累加已发货数量
        if (index !== -1) {
          this.productInstallationRecord[index].sentCount += Number(product.count);
        } else {
          this.productInstallationRecord.push({
            itemId: product.itemId,
            sentCount: Number(product.count),
            total: Number(product.productCount)
          });
        }
      }
    }
  }

  public handleAddShops(shops: Array<ShopList>): void {
    shops.forEach(x => {
      Vue.set(x, 'uuid', uniqueId('shop'));
      Vue.set(x, 'remark', '');
      Vue.set(x, 'finishDay', undefined);
      Vue.set(x, 'serveItems', undefined);
      Vue.set(x, 'budgetAmount', 0);
      Vue.set(x, 'planInstallTime', '');
      x.shopItemRelList = x.shopItemRelList ?? [];
      x.shopItemRelList.forEach(item => {
        delete item.id;
      });
    });
    this.installationNoticeForm.shopList?.unshift(...shops);
  }

  public cancel(): void {
    this.$router.go(-1);
    TagsViewModule.DelView(this.$route);
  }

  public async saveInstallationNotice(): Promise<void> {
    if (!this.verifyDeliveryDetails()) {
      return;
    }
    try {
      this.saveLoading = true;
      if (!this.id) {
        await installationNoticeService.post(this.installationNoticeForm as CreateInstallationNotice);
      } else {
        await installationNoticeService.put((this.installationNoticeForm as any) as InstallationNoticeInfo);
        this.needReloadList = true;
      }
      (this.$refs.attachment as InstallAttachment).deleteMinioFiles();
      Message.success(translation('operationRes.saveSuccess'));
      this.$router.go(-1);
      TagsViewModule.DelView(this.$route);
    } catch (error) {
      messageErrors(error);
    } finally {
      this.saveLoading = false;
    }
  }

  public saveAndPushInstallationNotice(): void {
    (this.$refs.installationNoticeForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      if (!this.verifyDeliveryDetails()) {
        return;
      }

      try {
        this.saveLoading = true;
        if (!this.id) {
          await installationNoticeService.saveAndPush(this.installationNoticeForm as CreateInstallationNotice);
        } else {
          await installationNoticeService.editAndPush((this.installationNoticeForm as any) as InstallationNoticeInfo);
          this.needReloadList = true;
        }
        (this.$refs.attachment as InstallAttachment).deleteMinioFiles();
        Message.success(translation('operationRes.saveSuccess'));
        TagsViewModule.DelView(this.$route);
        this.$router.go(-1);
      } catch (error) {
        messageErrors(error);
      } finally {
        this.saveLoading = false;
      }
    });
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/installation-notice') {
      to.meta!.needReload = this.needReloadList;
    }
    next();
  }

  public syncInstallAttachment(data: Array<InstallAttachmentsList>): void {
    this.installationNoticeForm.fileIdList = data.map(x => x.id);
  }

  /**
   * 验证发货明细，是否合法
   * @returns
   */
  private verifyDeliveryDetails(): boolean {
    const errors: Array<ApiError> = [];
    if (this.installationNoticeForm.shopList?.length === 0) {
      Message.warning(`${translation('addDeliveryNotice.pleaseAddstore')}`);
      return false;
    }
    this.installationNoticeForm.shopList?.forEach((item, index) => {
      if (
        isNullOrUndefinedForBaseType(item.finishDay) ||
        isNullOrUndefinedForBaseType(item.budgetAmount) ||
        isNullOrUndefinedForBaseType(item.serveItems) ||
        isNullOrUndefinedForBaseType(item.address)
      ) {
        let message = `第${index + 1}行: ${item.shopName}--`;
        for (const [key, value] of Object.entries(item)) {
          if (key === 'finishDay' && isNullOrUndefinedForBaseType(value)) {
            message = `${message}【完工日期】`;
          }
          if (key === 'budgetAmount' && isNullOrUndefinedForBaseType(value)) {
            message = `${message}【预算费用】`;
          }
          if (key === 'serveItems' && isNullOrUndefinedForBaseType(value)) {
            message = `${message}【服务项目】`;
          }
          if (key === 'address' && isNullOrUndefinedForBaseType(value)) {
            message = `${message}【详细地址】`;
          }
        }
        errors.push({
          message: `${message}信息未填写`,
          level: 1
        });
      }
    });

    // const shopWithMissingProps = this.installationNoticeForm.shopList?.find(
    //   x =>
    //     isNullOrUndefinedForBaseType(x.finishDay) ||
    //     isNullOrUndefinedForBaseType(x.budgetAmount) ||
    //     isNullOrUndefinedForBaseType(x.serveItems) ||
    //     isNullOrUndefinedForBaseType(x.address)
    // );
    // console.log('shopWithMissingProps', shopWithMissingProps);

    // if (shopWithMissingProps) {
    //   // Message.warning(
    //   //   `${shopWithMissingProps.shopName} —— ${translation('addInstallationNotice.verifyShopPropFailed')}`
    //   // );
    //   messageErrors(errors);
    //   return false;
    // }
    if (errors.length > 0) {
      messageErrors(errors);
      return false;
    }

    const verifyFailedShop = this.installationNoticeForm.shopList?.find(
      x => x.shopItemRelList.filter(y => y.count === 0).length > 0
    );
    if (verifyFailedShop) {
      this.productInstallations = verifyFailedShop.shopItemRelList;
      this.currentInstallationShopName = ` —— ${verifyFailedShop.shopName}`;
      this.installationProductKey = verifyFailedShop.uuid;
      Message.warning(`${translation('addInstallationNotice.verifyCountFailed')} —— ${verifyFailedShop.shopName}`);
      return false;
    }

    const emptyShop = this.installationNoticeForm.shopList?.find(x => x.shopItemRelList.length === 0);
    if (emptyShop) {
      this.productInstallations = emptyShop.shopItemRelList;
      this.currentInstallationShopName = ` —— ${emptyShop.shopName}`;
      this.installationProductKey = emptyShop.uuid;
      Message.warning(`${emptyShop.shopName} —— ${translation('addInstallationNotice.verifyEmptyFailed')}`);
      return false;
    }

    return true;
  }

  private calculateRemainCount(item: ShopItemReList): number {
    const sentCount = this.productInstallationRecord.find(x => x.itemId === item.itemId)?.sentCount ?? 0;
    return Number(item.productCount) - Number(sentCount) + Number(item.count);
  }

  private getInstallNoticeDetails(): void {
    installationNoticeService
      .getById(this.id)
      .then(res => {
        this.installationNoticeInfo = res;
        this.projectId = res.projectId;
        this.customerName = res.customerName;
        Object.assign(this.installationNoticeForm, res);
        this.installationNoticeForm.fileIdList =
          this.installationNoticeInfo.installAttachmentList?.map(x => x.id) || [];
      })
      .catch(error => {
        messageError(error);
      });
  }

  private initConfigInstallData(): void {
    installationNoticeService
      .getInstallDetailsByProject(this.projectId)
      .then(res => {
        this.handleAddShops(res);
      })
      .catch(error => {
        messageError(error);
      });
  }

  private initForAdd(): void {
    this.installationNoticeForm.projectId = Number(this.$route.query.projectId);
    this.projectId = Number(this.$route.query.projectId);
    this.installationNoticeForm.projectName = this.$route.query.projectName as string;
    this.customerName = this.$route.query.customerName as string;

    this.installationNoticeForm.fileIdList = [];
    this.installationNoticeForm.shopList = [];
    this.installationNoticeForm.remark = '';

    // 询问用户是否从项目中自动导入交货明细数据
    showConfirm(translation('addInstallationNotice.isInitInstalldataForProject'), 'info')
      .then(() => {
        this.initConfigInstallData();
      })
      .catch(() => {});
  }

  @Watch('installationNoticeForm.shopList')
  private calTotalBudgetAmount(): void {
    this.installationNoticeForm.shopList!.forEach((shop, index) => {
      shop.index = index + 1;
    });
    this.installationNoticeForm.totalBudgetAmount = this.installationNoticeForm.shopList!.reduce(
      (previousValue, shop) => previousValue + (shop.budgetAmount ?? 0),
      0
    );

    this.installationNoticeForm.totalBudgetAmount = Number(this.installationNoticeForm.totalBudgetAmount.toFixed(2));
  }
}
